/*

COLORS

NEW BPL MAIN COLOR
Primary: #484E80


Background: #474851
Surface: #121212
Primary Variant: #3700B3

*/

@font-face {
  font-family: proximaNova;
  src: url(ProximaNova-Regular.woff);
}

@font-face {
  font-family: proximaNovaBold;
  src: url(ProximaNova-Bold.woff);
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #474851;
  font-size: 16px;
  overflow-x: hidden;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
:focus {
  box-shadow: 1px 1px 1px #484E80!important;
}
*/

body #root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body .App {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

div {
  box-sizing: border-box;
}

button, select, input, textarea {
  color: #474851;
  font-size: 14px;
}

input:disabled {
  background: #f5f5f5;
}

select {
  background-color: #fff;
}

.bold {
  font-family: proximaNovaBold;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, input, div, button, p, textarea, select, option {
  font-family: proximaNova;
}

select, option {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: proximaNovaBold;
  color: #484E80;
  font-weight: normal;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

.no-color {
  background-color: #fff;
  color: #474851;
}

button {
  background-color: #fff
}

a {
  text-decoration: none;
  border-bottom: 1px solid;
  line-height: 1.3;
  color: #484E80;
  font-family: proximaNovaBold;
  font-weight: normal;
}

input, button, textarea {
  border-radius: 10px;
  box-sizing: border-box;
}

.App {
  margin: 0;
  padding: 0;
}

.sr-only {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.center {
  text-align: center;
}

.eighteen-point {
  font-size: 18px;
}

.capitalize {
  text-transform: capitalize;
}

a.no-border-bottom {
  border-bottom: none;
}

.main-app-container {
  padding: 10px 25px;
}

.navbar-buttons-container {
  display: flex;
  gap: 25px;
  align-items: center;
}

@media (width <= 1300px) {
  .navbar-buttons-container {
    gap: 21px;
  }
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

a.login-logout-button {
  padding: 4px 12px;
  font-weight: 600;
  color:#484E80;
  border: 2px solid #484E80;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-family: proximaNovaBold;
  font-weight: normal;
  box-sizing: border-box;
  font-size: 14px;
}

a.navbar-donate-button {
  padding: 4px 12px;
  font-weight: 600;
  color:#484E80;
  border: 2px solid rgb(135, 8, 94);
  background-color: rgba(169, 10, 118, 0.06);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  gap: 6px;
  box-sizing: border-box;
  font-family: proximaNovaBold;
  font-weight: normal;
  font-size: 14px;
}

a.navbar-donate-button svg {
  fill: rgb(135, 8, 94);
  position: relative;
  top: -1px;
  left: -1px;
}

a.get-a-card-nav-link {
  padding: 4px 12px;
  font-weight: 600;
  background-color:#484E80;
  color: #fff;
  border: 1px solid #484E80;
  font-family: proximaNovaBold;
  font-weight: normal;
  font-size: 14px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
}

a.navbar-locations-link {
  border-bottom: none;
  font-size: 16px;
  font-family: proximaNovaBold;
  font-weight: normal;
  font-weight: 400;
}

a.navbar-locations-link svg {
  margin-right: 2px;
  position: relative;
  top: 2px;
  fill: #484E80;
}

button.navbar-translate-button {
  font-size: 16px;
  color:#484E80;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: proximaNovaBold;
  font-weight: normal;
}

button.navbar-translate-button svg {
  fill:#484E80;
  position: relative;
  top: 1px;
  margin-right: 2px;
}

button.navbar-translate-button .navbar-translate-text {
  position: relative;
  top: -1px;
}

.alert-message {
  background-color: #A91717;
  color: #fff;
  width: 100%;
  padding: 16px 40px;
  font-size: 16px;
}

.red-text {
  color: #A91717;
}

.footer {
  width: 100%;
  min-height: 300px;
  background: #484E80;
  color: #fff;
  padding: 50px 70px 100px;
  font-family: proximaNova;
}

body .footer {
  letter-spacing: 0.2px;
  line-height: 1.5;
}

.footer a {
  font-family: proximaNova;
  color: #fff;
  border-bottom: none;
}

.footer-extra-links {
  display: flex;
  gap: 15px;
  flex-flow: row wrap;
}

.footer .accordions-container {
  display:none;
  max-width: 100%;
  border-radius: 15px;
}
.footer .accordions-main-content {
  /* padding: 0 20px; */
  padding-right: 5%;
  padding-left: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}
.footer a.toggle-menu{
  height: 40px;
  display: flex;
  border-bottom: none;
  justify-content: space-between;
  align-items: baseline;
}
.footer .menu.open {
  display: flex;
  transition: height .2s ease;
}
.footer .menu.closed {
  height: 0px;
  overflow: hidden;
  transition: height .2s ease;
  margin-bottom: 10px;
}
.footer .menu {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    margin-top: 10px;
    overflow: hidden;
    /* margin-bottom: 10px; */
}
.footer li a {
  padding: 0;
  font-size: 20px;
}
.footer ul.btn-toggle-nav.sub-menu {
  list-style: none;
  padding-left: 10px;
  margin: 0;
  padding: 0 10px 0 0;
  margin-left: 1.25rem;
  line-height: 1.7;
  margin-bottom:10px;
}
.footer .toggle-menu.nav-title h4 {
  font-size: 24px;
  font-family: 'proximaNovaBold';
  color: white;
  margin: 0 0 0 0;
}
.footer .toggle-menu.nav-title svg{
  stroke: white;
  overflow: visible;
}


.footer .scrolltotop {
    position: fixed;
    right: 15px;
    bottom: 100px;
    background-color: transparent;
    border: 0;
    opacity: .7;
    color: rgba(255,255,255,.899);
}
.footer .scrolltotop svg{
  height: 44px;
}

.footer .footer-columns-container {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 50px;
}


.footer-column {
  flex-basis: 200px;
  flex-grow: 1;
  padding-right: 10px;
}

.footer-column-label {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 16px;
  font-family: proximaNovaBold;
}

.footer-column a {
  font-size: 15px;
  color: #fff;
  margin-bottom: 4px;
  border-bottom: none;
  display: block;
  border-bottom: 1px solid transparent;
  width: fit-content;
  padding-bottom: 0px;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer .horizontal-links-container {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer .horizontal-links-container a {
  font-family: proximaNovaBold;
  font-size: 16px;
  color: #fff;
  border-bottom: none;
  display: block;
  border-bottom: 1px solid transparent;
  width: fit-content;
}

.footer .horizontal-links-container a:hover {
  text-decoration: underline;
}

.footer .horizontal-links-container .footer-logo {
  margin-top: -30px; /* NEEDS REVIEW */
  width:150px;
}

.footer .horizontal-links-container .footer-logo-link{
  margin-right:auto !important;
  flex-shrink: 0;
}

.footer .social-media-copywright-container {
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  margin-top: 16px;
  padding: 16px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.footer  .footer-social-icons svg {
  height: 24px;
  fill: #fff;
}
.footer  .footer-social-icons svg:hover {
	fill: #ffffffb0;
}
.footer-social-icons {
  display: flex;
  gap: 20px;
}
.footer .footer-social-icons.mobile {
  display: none;
}


.footer-copywright-text {
  color: #fff;
}
@media only screen and (max-width:768px /* originally 700 */){
  .footer .social-media-copywright-container {
    border-top: 0;
    margin: 0;
    padding: 0;
  }
  .footer .social-media-copywright-container .footer-copywright-text {
    margin-top: 70px;
  }
  
  .footer-social-icons {
    display: none;
    gap: 20px;
  }
  
  .footer .social-media-copywright-container .footer-social-icons svg {
    height: 24px;
    fill: #fff;
  }
  
  .footer-copywright-text {
    color: #fff;
  }
  .footer .footer-social-icons.mobile {
    display: flex;
    gap: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}
/* ## FOOTER ACCORDION MENU ## */
@media only screen and (max-width: 768px){
  
}

.main-app-container {
  background: #f3f3f3;
  min-height: calc(100vh);
}

.item-page .main-app-container{
  background: #fff;
  padding: 10px 50px;
}

:focus-visible {
  outline: unset;
  outline-offset: unset;
}

.background-main {
  background-color: #484E80;
  color: #fff;
}

.main-color {
  color: #484E80;
}

.background-secondary {
  background-color: #9C2273;
  color: #fff;
}

.secondary-color {
  color:#9C2273;
}

button {
  border:none;
}

button:hover {
  cursor: pointer;
}

select {
  display: flex;
  height: 40px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #484E80;
}

select option {
  font-size: 16px;
  font-family: proximaNova;
  padding: 5px 10px;
}

input[type="email"], input[type="text"], input[type="password"], textarea {
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #484E80;
  font-size: 16px;
  min-width: 250px;
}

#header-homepage-link-container {
  position: relative;
  left: 30px; 
}

a#header-homepage-link {
  border-bottom: none;
}

div a.breadcrumb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 30px;
  border-bottom: none;
  color:#474851;
  font-size: 15px;
}

a.breadcrumb div {
  font-family: proximaNovaBold;
  font-weight: normal;
}

.booklist-header div a.breadcrumb { 
  margin-bottom: 10px;
}

.item-page a.breadcrumb {
  padding: 0px 0px 2px;
  border-radius: 0px;
}

.item-page a.breadcrumb div{
  border-bottom: 1px solid;
}

.item-page a.breadcrumb svg {
  position: relative;
  top: -1px;
  height: 20px;
  left: 2px;
}

.item-page a.breadcrumb svg path {
  fill:#474851;
}

.search-page a.breadcrumb svg {
  height: 14px;
  fill:#474851;
  position: relative;
  top: 1px;
}

.search-page .app-header {
  padding: 20px 0px 0px;
}

.item-page .app-header {
  padding: 20px 20px 20px;
}

.account-page .app-header {
  padding: 10px 18px 5px;
}

h1 {
  margin: 15px 0px;
}

.account-page .app-header h1 {
  margin: 8px 0px 12px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9B9B9B;
  padding: 6px 40px 4px;
}

.nav-bar .logo-container img {
  width: 226px;
  position: relative;
  top: 2px;
}

.gtranslate_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 15px;
  z-index: 1;
}

.gtranslate_wrapper svg {
  height: 17px;
  fill:#484E80;
  position: relative;
  top: -1px;
  left: 21px;
  z-index: 1;
  display: none;
}

.gtranslate_wrapper select {
  border:none;
  width: 110px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 30px;
  background: transparent;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
  color: #484E80;
  position: relative;
  top: 0px;
  font-size: 16px;
  font-family: proximaNovaBold;
  font-weight: normal;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: -1;
  box-sizing: border-box;
  display: block;
}

.gtranslate_wrapper select option {
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
  color: #484E80;
  font-size: 16px;
  font-family: proximaNovaBold;
  font-weight: normal;
}

button.hamburger-menu-button {
  background-color: transparent;
}

button.hamburger-menu-button svg.svg-inline--fa {
  font-size: 24px;
}

section {
  display: flex;
  margin-top: 30px;
}

.account-page section {
  display: block;
  margin: 0px 20px 20px;
}

.flex {
  display: flex;
  gap: 8px;
}

.flex-padding {
  display: flex;
  gap: 8px;
  padding: 16px 0px 16px 0px;
}

.flex-small-gap {
  display: flex;
  gap: 20px;
}

.flex-medium-gap {
  display: flex;
  gap: 50px;
}

.wrap {
  flex-flow: row wrap;
}

.full-width {
  width: 100%;
}

span.underline {
  padding-bottom: 2px;
  border-bottom: 2px solid;
}

.white-tile-container {
  background: #fff;
  border-radius: 15px;
  padding: 18px 24px;
  width: fit-content;
  margin-bottom: 20px;
}

.edit-account-tiles-container {
  display: flex;
  flex-flow: row wrap;
  gap: 40px;
}

.edit-account-tiles-container .white-tile-container {
  flex-basis: 600px;
  margin-bottom: unset;
}

.white-tile-container h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}

.default-blue-button {
  align-items: center;
  background: #484E80;
  border-radius: 22px;
  border: 1px solid #484E80;
  color: #fff;
  display: flex;
  font-weight: normal;
  gap: 8px;
  justify-content: center;
  margin-top: 16px;
  padding: 12px 20px;
  width: fit-content;
}

.default-white-button {
  align-items: center;
  background: #fff;
  border-radius: 22px;
  border: 1px solid #484E80;
  color: #484E80;
  display: flex;
  font-weight: normal;
  gap: 8px;
  justify-content: center;
  margin-top: 16px;
  padding: 12px 20px;
  width: fit-content;
}

.item-page section {
  display: block;
  margin: 20px;
}

.hide {
  display: none!important;
}

#global-searchbar {
  display: flex;
  justify-content: center;
  width: 100%;
}

.searchbox {
  width: 700px;
}

.searchbar-container {
  display: flex;
  border: 2px solid #484E80;
  padding: 10px 20px;
  border-radius: 24px;
  box-sizing: border-box;
  background: #fff;
}

.searchbar-container.autocomplete-active {
  border-radius: 24px 24px 0px 0px;
  border-bottom: none;
}

#global-searchbar input {
  font-size: 18px;
  color: #484E80;
  margin-bottom: 0px;
  display: block;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  flex-basis: 400px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0px;
  margin: 0px;
}

#global-searchbar input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #484E80;
  opacity: 1; /* Firefox */
}

#global-searchbar input::-ms-input-placeholder { /* Microsoft Edge */
  color: #484E80;
}

#global-searchbar button[type="submit"] {
  background: transparent;
  color: #484E80;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

#global-searchbar button[type="submit"] svg {
  color:#484E80;
  transform: scale(1.4);
  position: relative;
  top: 2px;
}

#global-searchbar button[type="submit"] svg path {
  stroke:#484E80;
}

.filterset.closed .filterset-autocomplete-search {
  display: none;
}

.filterset-autocomplete-search {
  border: 1px solid #484E80;
  padding: 6px 18px;
  border-radius: 24px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 15px;
  position: absolute;
  background-color: #fff;
  max-width: 290px;
  z-index: 5;
}

.filterset-autocomplete-search + div {
  margin-top: 56px;
}

.filterset-autocomplete-search svg {
  height: 22px;
  position: relative;
  left: 8px;
}

.filterset-autocomplete-search svg path {
  stroke: #484E80;
  fill: transparent
}

button.filters-autocomplete-link {
  display: block;
  padding: 6px 2px;
  border-bottom: none;
  font-family: proximaNova;
  font-size: 16px;
  color:#484E80;
  text-align: left;
}

button.filters-autocomplete-link:hover, button.filters-autocomplete-link:focus {
  background-color: #FCFCFD;
}

.filters-autocomplete-link:first-of-type {
  margin-top: 4px;
}


.filters {
  width: 420px;
  min-height: 800px;
  margin-right: 40px;
  padding: 10px 40px;
  border-radius: 15px;
  background: #fff;
}

.sort-and-num-results-container {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-bottom: 20px;
  align-items: center;
  gap: 15px;
}

.sort-options {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-flow: row wrap;
}

.sort-options label {
  font-size: 20px;
}

select#sort-selectbox {
  display: flex;
  width: 170px;
  height: 40px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #484E80;
}

select#sort-selectbox option {
  font-size: 16px;
  font-family: proximaNova;
}

a.toggle-filterset {
  display: flex;
  border-bottom: none;
  justify-content: space-between;
  align-items: baseline;
}

.radio-primary-label {
  font-family: proximaNovaBold;
  font-weight: normal;
}

.radio-secondary-label {
  color:#6E6F7B;
}

.radio-button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}

.filters h3 {
  margin: 20px 0px 15px;
  font-size: 28px;
}

.filters h4 {
  margin: 10px 0px;
}

.filters hr {
  margin: 18px 0px 28px;
  border: 1px solid #ccc;
}

.filterset {
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  margin-top: 10px;
  overflow: hidden;
}

.filterset.closed {
  height: 0px;
  overflow: hidden;
  transition: height .1s ease;
}

.filterset.open {
  display: flex;
  transition: height .1s ease;
}

.toggle-filterset svg {
  rotate: 0deg;
  transition: rotate .1s ease;
}

.availability-filters svg.svg-inline--fa {
  font-size: 18px;
}

.filters button svg {
  height: 20px;
  fill:#484E80;
}

.filters #catalog-filterset-fiction button svg {
  height: 28px;
  fill:#484E80;
  margin-bottom: 2px;
}

.filter-square-btn {
  border-radius: 10px;
  border: 1px solid #484E80;
  display: flex;
  width: 90px;
  height: 90px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  color: #484E80;
}

.filter-square-btn.active {
  background-color: #E2EAF8;
  transition: background-color .2s;
}

.large-filter-text {
  font-size: 25px;
  font-family: proximaNovaBold;
  font-weight: normal;
}

input[type="text"].filter-search-input {
  border: none;
  padding: 2px;
  margin: 0px;
  color:#484E80;
  min-width: unset;
  max-width: unset;
  width: 220px;
}

input[type="text"].filter-search-input::placeholder {
  color:#484E80;
}

.search-results-container {
  width: 100%;
}

.result-container {
  padding: 30px;
  margin: 0px 0px 40px 0px;
  text-align: left;
  display: flex;
  word-break: break-word;
  border-radius: 20px;
  background-color: #fff;
}

.result-container.website {
  gap: 30px;
}

.result-type {
  font-family: proximaNovaBold;
  margin-bottom: 5px;
}

.result-title {
  margin-bottom: 5px;
  font-size: 20px;
  font-family: proximaNovaBold;
}

.result-author {
  margin-bottom: 15px;
  color: #6E6F7B;
  font-size: 14px;
}

.result-author a {
  font-family: proximaNova;
  color: #6E6F7B;
  font-size: 14px;
}

.result-detail-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.result-detail-actions {
  margin-top: 32px;
  display: flex;
  gap: 10px;
}

div div.grouped-search-format-listing-container {
  margin: 12px 0px;
}

div div.grouped-formats-listing-container {
  margin-bottom: 20px;
}

a.grouped-search-format-listing {
  border-bottom: 1px solid;
  font-family: 'proximaNova';
  font-size: 15px;
}

.grouped-search-format-listing svg {
  margin-right: 8px;
  height: 14px;
  fill:#484E80;
}

.item-page .result-detail-actions {
  gap: 20px;
}

.result-detail-actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 100px;
  padding: 10px 24px 10px 16px;
}

.item-page .result-detail-actions button {
  padding: 12px 28px 12px 18px;
  font-size: 16px;
}

.result-detail-actions button img {
  position: relative;
  top: -1px;
}

button.get-this-item {
  background: #484E80;
  color: #fff;
}

button.add-to-list {
  background: #fff;
  border: 1px solid #484E80;
  color: #484E80;
}

button.share-item {
  background: transparent;
  /* border: 1px solid #484E80; */
  color: #484E80;
  fill: #484E80;
  padding-left: 0 !important;
}

.hide-share-options{
  position: absolute;
  float: right;
  background: transparent;
  top: 0;
  right: 0;
  padding: 8px !important;
}

.hide-share-options svg {
  width: 10px;
  height: 10px;
  position: relative;
  top: 6px;
  left: -2px;
}

.hide-share-options svg path {
  fill:#484E80;
}

.share-options{
  z-index: 1;
  position:absolute;
  width: 150px;
  border: #484E80 solid 1px;
  border-radius: 5px;
  padding: 0px 10px;
  background: white;
  margin-left: -10px;
  margin-top: 45px;
  /* margin-top: 10px; */
} 

.booklist-share-container {
  margin-top: 20px;
}

.booklist-share-container button.share-item svg {
  height: 14px;
  margin-right: 3px;
  position: relative;
  top: 1px;
}

.booklist-share-container .share-options{
  margin-left: 60px;
  margin-top: 32px;
} 

.booklist-share-container .hide-share-options svg {
  top: 3px;
}

.share{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 540px){
  .item-page .result-detail-actions{
    flex-direction: column;
  }
  .share-item{
    padding: 4px 0px 0px 0px !important;
  }
  h1.item-page-title {
    text-align: center;
  }
  h2.item-page-author{
    text-align: center;
  }
  .item-page .result-detail-actions{
    gap: 12px !important;
  }
}
.share-copy-link {
  margin: 8px 0px;
  a {
    border-bottom: 0px;
    font-family: proximaNova;
    font-size: 15px;
  }
  a:hover{
    color: #474851;
  }
  svg {
    position: relative;
    top: 2px;
    width: 16px;
  }
}
.share-copy-link-only{
  cursor: pointer;
}
.share-arrow{
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
  position: absolute;
  top: -6px;
  left: 60px;
  fill: #484E80;
  background-size: 11.5px 6.5px;
  background: white;
  svg{
    position: absolute;
    top: -1px;
    width: 12px;
    height: 12px;
  }
}
.share-link-icon{
  fill: #484E80;
  margin-right: 5px;
  align-items: center;
  padding: 0;
  justify-content: center;
}

button.rebalancing-claim-button {
  background: #fff;
  border: 1px solid #484E80;
  color: #484E80;
}

button.rebalancing-claim-button svg {
  fill: #484E80;
}

.color-available {
  font-weight: bold;
  color: #4A7C30;
}

.color-pending {
  font-weight: bold;
  color: #C1472E;
}

.item-page .color-available, .item-page .color-pending {
  font-weight: 444;
}


.expand-formats {
  color:#474851;
}

.availability-container svg {
  position: relative;
  left: 1px;
}

.availability-container {
  margin-bottom: 10px;
}

.no-result-container {
  padding: 20px 30px;
  margin: 0px;
  border-radius: 30px;
  background-color: #fff;
}

.image-container {
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  left: -15px;
}

.website .image-container {
  position: relative;
  left: -5px;
  margin-right: 15px;
}

.image-container img {
  object-fit: contain;
  width: 220px;
  height: 220px;
  object-position: top;
}

.image-container a {
  border-bottom: none;
}

.subject-pill-btn-container {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-top: 20px;
}

button.subject-pill-btn {
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 24px;
  padding: 3px 10px;
  border: 1px solid #474851;
}

a.subject-pill-btn {
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 24px;
  padding: 3px 10px;
  border: 1px solid #484E80;
  font-family: proximaNova;
  font-size: 14px;
}

.launch-filters-btn {
  display: none;
}

.close-modal-overlay {
  display: none;
}

.pro-tip-container {
  display: flex;
  padding: 24px;
  gap: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  color:#484E80;
  margin-top: 30px;
}

.pro-tip-title {
  font-weight: bold;
  margin-bottom: 3px;
  position: relative;
  top: -2px;
}

button.clear-all-filters-footer {
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 22px;
  border: 1px solid #484E80;
  color: #484E80;
  width: 195px;
  font-weight: 600;
}

button.view-results-filters-footer {
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 22px;
  border: 1px solid #484E80;
  color: #fff;
  background: #484E80;
  width: 195px;
  font-weight: 600;
}

.fixed-filters-footer {
  display: none;
}

.active-filters-container-parent {
  margin-bottom: 20px;
}

.selected-filters-text {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 12px;
  color: #6E6F7B;
}

.active-filters-container {
  display: flex;
  gap: 8px;
  flex-flow: row wrap;
}

.active-filter {
  display: flex;
  padding: 6px 12px 6px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid #484E80;
  background:#E2EAF8;
  color:#484E80;
}

.active-filter.clear-all-button {
  background: #fff;
}

.fixed-filters-header hr, .fixed-filters-footer hr {
  display: none;
}

.formats-container {
  margin-top: 40px;
}

.tab-flex-container {
  display: flex;
  gap: 5px;
  margin-bottom: 25px;
}

.tab-flex-container a {
  border-bottom: none;
  padding: 12px 20px;
  background-color: #E2EAF8;
  border-radius: 8px 8px 0px 0px;
  transition: background-color .3s, color .3s;
}

.tab-flex-container a.active {
  background-color: #484E80;
  color: #fff;
  transition: background-color .3s, color .3s;
}

a.cam-carousel-item {
  border: none;
  scroll-snap-align: start;
}

a.cam-carousel-item img { 
  height: 200px;
  object-fit: contain;
  object-position: top left;
}

@media (width > 1400px) {
  a.cam-carousel-item img { 
    height: 240px;
  }
}

@media (width > 900px) {
  .item-page a.cam-carousel-item img { 
    height: 280px;
  }
}


.cam-carousel {
  display: flex;
  gap: 15px;
}

.cam-carousel-content-container {
  display: flex;
  gap: 40px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.cam-carousel-content-container::-webkit-scrollbar {
  display: none;
}

.carousel-item-title {
  margin-top: 8px;
  font-family: proximaNovaBold;
  color: #474851;
  word-wrap: break-word;
}

.carousel-item-author {
  font-size: 14px;
  color: #6E6F7B
}

.cam-carousel button svg {
  height: 36px;
  fill:#484E80;
  position: relative;
  top: -20px;
}

.search-sparkle-tip {
  display: flex;
  gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  width: fit-content;
  align-items: center;
}

.search-sparkle-tip svg {
  height: 28px;
  
}

.search-sparkle-tip svg path {
  fill:#484E80;
}

a.filter-pill-button {
  display: flex;
  padding: 6px 14px;
  align-items: baseline;
  justify-content: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #484E80;
  background-color:#fff;
  color:#484E80;
  font-family: proximaNova;
  font-size: 14px;
}

a.filter-pill-button.active {
  background-color:#E2EAF8;
  transition: background-color .2s;
}

a.filter-pill-button svg {
  height: 14px;
  fill: #484E80;
  position: relative;
  top: 1px;
  left: -1px;
}

button.filter-toggle-facet {
  font-size: 14px;
  padding: 0px;
  margin-left: auto;
  margin-bottom: 30px;
  border-bottom: 1px solid;
  border-radius: 0px;
}

.search-page [activated="yes"] {
  background-color:#E2EAF8;
  transition: background-color .2s;
}

.homepage-discovery-container h3 {
  color:#474851;
  margin-top: 5px;
}

.pill-link-discovery-container {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-bottom: 20px;
}

a.discovery-pill-link {
  display: flex;
  padding: 8px 14px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 1px solid #484E80;
  background: #FFF;
  font-size: 14px;
  font-family: proximaNova;
}

.dark-gray {
  color: #6E6F7B;
}

.filter-toggle-counts {
  color: #6E6F7B;
  margin-left: 6px;
}

.subtext {
  font-size: 12px;
}

.discover-carousel-card {
  padding: 16px;
  display: block;
  border-radius: 15px;
  background: #FFF;
  margin: 40px 0px;
  max-width: calc(100vw - 520px);
}

.discover-carousel-card h3 {
  color: #474851;
  margin-top: 5px;
}

.search-carousel-tip-top-container h3{
  margin-bottom: 0px;
}

.search-carousel-tip-top-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 48px 0px 8px;
  margin-bottom: 16px;
  gap: 16px;
}

.search-carousel-tip-top-container .search-carousel-tip-link-container {
  display: flex;
  flex-shrink: 0;
}

.search-carousel-tip-top-container a {
  color: #474851;
  align-self: flex-end;
  position: relative;
  top: -3px;
  line-height: 1.2;
}

.homepage-discovery-container {
  max-width: calc(100vw - 550px);
}

.filter-checkbox-container {
  display: flex; 
  margin-bottom: 5px;
  gap: 10px;
}

a.show-more-filters-link {
  font-family: proximaNova;
  font-weight: 400;
  margin-top: 15px;
  display: block;
  border-bottom: none;
  font-size: 14px;
  width: 100%;
}

.filter-pill-button svg.svg-inline--fa {
  position: relative;
  top: -1px;
}

span.show-more-text-span {
  border-bottom: none;
}

svg.flip {
  rotate: 180deg;
  transition: rotate .1s ease;
}

svg {
  rotate: 0deg;
  transition: rotate .1s ease;
}

a.show-more-filters-link svg {
  position: relative;
  top: -2px;
  margin-left: 5px;
}

input[type="checkbox"]:hover, label:hover {
  cursor: pointer;
}

.fiction-filters button svg.svg-inline--fa {
  font-size: 32px;
}

#publication-year-form {
  width: 100%;
}

#publication-year-form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #484E80;
}

#publication-year-form input[type="text"] {
  border-radius: 5px;
  border: 1px solid #484E80;
  padding: 5px 10px;
  display: block;
  box-sizing: border-box;
  width: 100px;
  font-size: 16px;
  min-width: unset;
}

#publication-year-form input[type="submit"] {
  border-radius: 5px;
  border: 1px solid #484E80;
  padding: 5px 10px;
  display: block;
  font-size: 16px;
  background-color: #fff;
  color:#484E80;
}

#publication-year-form .flex {
  margin-bottom: 10px;
  align-items: flex-end;
  gap: 12px;
}

.web-summary {
  margin-top: 10px;
}

.booklist-header {
  padding: 0px 10px;
  margin-bottom: 25px;
}

h1.booklist-title {
  margin-bottom: 5px;
  font-size: 38px;
}

h2.booklist-author {
  margin-top: 0px;
  padding: 0px;
  font-size: 22px;
}

#fixed-bottom-nav {
  display: flex;
  position: fixed;
  box-sizing: border-box;
  bottom: 0px;
  border-top: 1px solid #D1D1DA;
  background: #FFF;
  /*
  padding: 10px 16px;
  */
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-evenly;
  z-index: 5;
}

button.bottom-nav-btn {
  font-size: 16px;
}

.pagination-container {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  justify-content: center;
}

.pagination-container button {
  border-radius: 10px;
  width: 42px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-container button svg {
  height: 24px;
}

.pagination-container button.disabled svg {
  color:#ccc;
  fill: #ccc;
}

div.event-top-extras-container {
  margin-top: 4px;
}

div.event-date-location-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
}

div.event-date-location-container div.flex {
  margin-right: 15px;
  margin-bottom: 5px;
}

div.event-date-location-container div.flex div {
  font-size: 17px;
}

.event-date-location-container svg {
  height: 18px;
  width: 18px;
}

.event-date-location-container svg path{
  fill:#484E80;
}

.event-canceled-msg {
  padding: 0px;
  font-family: proximaNovaBold;
  color: #9C2273;
  margin-top: 4px;
  font-size: 17px;
}

.event-registration-msg {
  padding: 0px;
  font-family: proximaNovaBold;
  color:#474851;
  margin-top: 4px;
  font-size: 17px;
}

button.booklist-more-actions-btn {
  display: flex;
  border-radius: 8px;
}

.more-actions-dropdown-container {
  position: absolute;
}

.more-actions-dropdown {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 4px 10px;
  background-color: #fff;
  position: relative;
  left: -70px;
  top: -2px;
}

.more-actions-dropdown-container button {
  padding: 10px 0px;
  border-radius: 0;
  text-align: left;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 90px;
}

.more-actions-dropdown-container button:last-of-type {
  border-bottom: none;
}

.event-tags-container {
  margin-top: 15px;
}

#event-date-filters a.filter-pill-button {
  padding: 6px;
  width: 20px;
} 

button#apply-event-date-input {
  border: 1px solid #484E80;
  color:#484E80;
}

input#event-date-input {
  color:#484E80;
}

input#event-date-input::placeholder {
  color:#484E80;
}

#event-date-filters label {
  color:#484E80;
  margin-bottom: 5px;
  display: block;
}

.event-date-error-msg {
  margin-bottom: 5px;
  color: #A91717;
}

.color-error {
  color: #A91717;
}

.autocomplete-search-container {
  position: relative;
  font-size: 16px;
  top: -10px;
}

.autocomplete-content-container {
  position: absolute;
  width: 100%;
  background-color: #fff;
  padding: 10px 0px 18px;
  border-radius: 0px 0px 24px 24px;
  
  border-left: 2px solid #484E80;
  border-right: 2px solid #484E80;
  border-bottom: 2px solid #484E80;
  z-index: 3;
}

.filter-autocomplete {
  position: absolute;
  width: 100%;
  background-color: #fff;
  padding: 10px 0px 18px;
  border-radius: 0px 0px 24px 24px;
  
  border-left: 1px solid #484E80;
  border-right: 1px solid #484E80;
  border-bottom: 1px solid #484E80;
  z-index: 3;
}

.autocomplete-content-container:empty {
  display: none;
}

.autocomplete-search-link-container {
  padding: 0px;
}

.autocomplete-search-link-container:hover, .autocomplete-search-link-container:focus {
  background-color: #eeeeee;
}

a.autocomplete-search-link {
  border-bottom: none;
  font-family: proximaNova;
  display: flex;
  padding: 10px 20px;
}

a.autocomplete-search-link svg {
  margin-right: 12px;
  position: relative;
  top: 2px;
}

a.autocomplete-search-link svg path {
  stroke:#484E80
}

a.autocomplete-search-link-filter {
  border-bottom: none;
  font-family: proximaNova;
  display: flex;
  padding: 10px 20px;
}

a.autocomplete-search-link svg, a.autocomplete-search-link-filter svg {
  margin-right: 12px;
  position: relative;
  top: 2px;
}

a.autocomplete-search-link svg path, a.autocomplete-search-link-filter svg path {
  stroke:#484E80
}

.pin-filters-container {
  display: flex;
  gap: 5px;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
}

.active-filters-pin-filters-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

button#pin-filters {
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid;
  padding: 2px 0px;
  margin-top: 30px;
}

/* Legacy CSS for old pin filters toggle switch */
input#pin-filters {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

label#pin-filters-label {
  display: inline-block;
  box-sizing: border-box;
  background-color: #FCFCFD;
  border-radius: 100px;
  border: 2px solid #7F808C;
  width: 66px;
  height: 32px;
  position: relative;
  top: -5px
}

label#pin-filters-label.checked {
  /*
  background-color: #9C2273;
  border: 2px solid #9C2273; 
  */
  background-color: #484E80;
  border: 2px solid #484E80; 
}

label#pin-filters-label svg {
  fill: #7F808C;
  position: relative;
  top: 2px;
  left: 2px;
  transition: left .2s;
}

label#pin-filters-label.checked svg {
  fill: #FCFCFD;
  position: relative;
  top: 2px;
  left: 35px;
  transition: left .2s;
}

.jump-to-page-container {
  display: flex;
  gap: 4px;
  align-items: center;
}

form#pagination-form input[type="text"] {
  width: 40px;
  min-width: unset;
  padding: 2px;
  text-align: center;
}

input#dewey-start, input#dewey-end {
  width: 100px;
  min-width: unset;
}

.booklist-item-count {
  margin-bottom: 20px;
}

.no-internet-message {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 12px;
  background-color: #474851;
  color: #fff;
  align-items: center;
}

.no-internet-message svg {
  height: 26px;
}

.no-internet-message svg path {
  fill: #fff;
}

.tool-message-container {
  background-color: #c1472e20;
  display: flex;
  gap: 16px;
  padding: 12px;
  margin-top: 20px;
  border-radius: 6px;
  border: 2px solid #c1472e;
  max-width: 900px;
  align-items: center;
  margin-bottom: 20px;
}

.tool-message-container svg {
  fill: #c1472e;
  height: 20px;
}

/*

ITEM PAGE SPECIFIC CSS

*/

.item-page-header-flex-container {
  margin-top: 40px;
  display: flex;
  gap: 80px;
}

.item-page-image-container img {
  object-position: top left;
  object-fit: contain;
  width: 250px;
  height: 305px;
}

.item-page-header-text {
  flex-shrink: 2;
  width: -webkit-fill-available;
  max-width: 1100px;
  flex-grow: 1;
}

h1.item-page-title {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  line-height: 1;
}

h2.item-page-author {
  margin-top: 0px;
}

h2.item-page-author a {
  color: #6E6F7B;
  font-size: 18px;
}

.item-page h3 {
  margin-top: 35px;
}

.format-selection-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 250px));
  grid-gap: 1rem;
  margin-top: 5px;
  
}

a.individual-format {
  padding: 5px 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  color: #474851;
}

a.individual-format.format-selected { 
  border: 2px solid #484E80;
  cursor: default;
}

.primary-item-details-container {
  display: flex;
  flex-flow: row wrap;
  gap: calc(3vw);
  margin-bottom: 25px;
}

.holding-locations-table {
  max-height: 400px;
  overflow-y: scroll;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 0px 10px;
  max-width: 1200px;
  margin-bottom: 40px;
}

.item-location-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  border-bottom: 1px solid #ccc;
  padding: 15px;
}

div.sample-link-container {
  display: flex;
  justify-content: center;
  position: relative;
  left: -8px;
  margin-top: 5px;
  margin-bottom: 10px;
}

a.sample-link {
  border-bottom: none;
  font-size: 18px;
}

a.sample-link svg {
  position: relative;
  top: 1px;
  left: -5px;
  height: 16px;
}

a.sample-link svg path {
  fill:#484E80;
}

.item-location-row:last-of-type {
  border-bottom: none;
}

.item-location-row svg {
  margin-right: 10px;
  position: relative;
  top: 1px;
  fill:#474851;
}

.only-show-available-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  max-width: 1200px;
}

.metadata-section {
  margin-bottom: 50px;
  margin-top: 50px;
}

.metadata-group-container {
  margin-bottom: 20px;
}

.item-page .metadata-group-label {
  margin-bottom: 6px;
}

.marc-group {
  display: flex;
  gap: 15px;
  margin-bottom: 6px;
}

.marc-tag {
  font-weight: bold;
  width: 50px;
  flex-shrink: 0;
}

a.reviews-link {
  font-family: proximaNova;
  margin-bottom: 8px;
  display: inline-block;
}

.order-count {
  margin-left: auto;
}

.order-count svg {
  fill:#6b6b6b;
  position: relative;
  top: 1px;
}

.individual-order-listing {
  margin-bottom: 6px;
}

.holding-table-item {
  padding: 10px 0px;
  border-bottom: 2px solid #ccc;
  width: fit-content;
}

.holding-table-item:last-of-type {
  border-bottom: none;
}

.holding-table-item > div {
  margin-bottom: 5px;
}

.related-booklists-container {
  display: grid;
  max-width: 1400px;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}

@media only screen and (max-width: 1400px) {
  .related-booklists-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .related-booklists-container {
    grid-template-columns: 1fr;
  }
}

a.related-booklist {
  border: 2px solid #9C2273;
  padding: 15px 20px 15px 10px;
  border-radius: 12px;
  box-shadow: 1px 1px #9C2273;
  background-color: #a90a7706!important;
}

a.related-booklist .no-color {
  background-color: transparent;
}

.related-booklist-image-container {
 flex-basis: 150px;
 flex-shrink: 0;
 height: 180px;
 overflow: hidden;
}

a.related-booklist img {
  object-fit: contain;
  width: 140px;
  height: 140px;
}

/*
a.related-booklist img.booklist-third-image {
  position: relative;
  left: -10px;
}
*/

a.related-booklist img.booklist-second-image {
  position: relative;
  left: -15px;
  top: 0px;
}

a.related-booklist img.booklist-first-image {
  position: relative;
  left: 20px;
  top: -110px;
}

.related-booklist-title {
  font-size: 18px;
  font-family: proximaNovaBold;
  margin-top: 4px;
}

.archival-collection-flex-message {
  display: flex;
  gap: 20px;
  padding: 5px 20px;
  border-radius: 10px;
  /*background-color: rgba(156, 34, 115, .1);*/
  border: 2px solid #484E80;
  width: fit-content;
}

@media (width < 500px) {
  .archival-collection-flex-message {
    padding: 5px 20px;
    gap: 0px;
  }

  .archival-collection-flex-message svg {
    display: none;
  }
}

.archival-collection-flex-message p {
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.archival-collection-flex-message svg {
  height: 28px;
  fill:#484E80;
  position: relative;
  top: 15px;
}

.archivesspace-tags-container .metadata-group-label {
  font-size: 22px;
}


.overdrive-review-container {
  max-width: 1200px;
}

.overdrive-review-container hr {
  margin: 60px 20%;
  color:#484E80;
  border: 1px solid #484E80;
}

.overdrive-review-container:last-of-type hr {
  display: none;
}

div.overdrive-review-container a {
  border-bottom: none;
}

.review-icons-container {
  display: flex;
  flex-flow: row wrap;
  gap: 40px;
  margin: 50px 0px;
}

.review-icons-container a {
  border-bottom: none;
}

.review-icons-container img {
  height: 100px;
}

@media (width < 500px) {
  div.review-icons-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 50px 0px;
  }

  div.review-icons-container img {
    height: 80px;
  }
}


/*

MY ACCOUNT CSS

*/


div.my-account-section-container {
  display: flex;
  gap: 50px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

div.my-account-section-container::-webkit-scrollbar {
  display: none;
}

button.my-account-section {
  display: flex;
  color: #6E6F7B;
  font-family: proximaNovaBold;
  font-size: 15px;
  text-transform: uppercase;
  background: transparent;
  padding: 0px 0px 5px;
  flex-shrink: 0;
  border-radius: 0px;
  border-bottom: 2px solid transparent;
}


button.my-account-section.active {
  color:#484E80;
}

/*
button.my-account-section.transition {
  color:#474851;
  border-bottom: 2px solid transparent;
}
*/

#my-account-section-underline-track {
  padding: 0px;
  margin: 0px;
  border: none;
  overflow: hidden;
  position: relative;
  top: -4px;
}

#my-account-section-underline {
  display: block;
  position: relative;
  content: "";
  top: 0px;
  left: 0px;
  width: 88px;
  height: 2px;
  background-color: #484E80;
  border: 0px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
  border: none;
  overflow: hidden;
  transition: left .4s ease, width .4s ease;
}

#my-account-section-underline.no-transition {
  transition: none;
}

button#my-account-scroll-right {
  position: absolute;
  right: 5px;
  border-radius: 0px;
  padding: 0px 5px;
  background-color: #f3f3f3;
}

button#my-account-scroll-left {
  position: absolute;
  left: 5px;
  border-radius: 0px;
  padding: 0px 5px;
  background-color: #f3f3f3;
}

button#my-account-scroll-left svg{
  fill:#484E80;
  position: relative;
  top: -6px;
}

button#my-account-scroll-right svg{
  fill:#484E80;
  position: relative;
  top: -6px;
}

.my-account-content-container {
  margin-top: 30px;
}

.my-account-content-container h2 {
  display: none;
}

#my-account-login-form label {
  display: block;
  font-family: proximaNovaBold;
  margin-bottom: 10px;
}

#my-account-login-form button[type=submit] {
  align-items: center;
  align-self: stretch;
  background: #484E80;
  border-radius: 50px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  margin-top: 30px;
  padding: 12px 20px;
}

.my-account-login-container {
  margin-left: 20px;
  margin-top: 30px;
}

#my-account-login-invalid-msg {
  margin-bottom: 20px;
}

.account-page h1 {
  text-transform: capitalize;
}

.checkouts-holds-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media only screen and (max-width: 1700px) {
  .checkouts-holds-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1200px) {
  .checkouts-holds-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 800px) {
  .checkouts-holds-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.my-account-catalog-record {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /*
  justify-content: space-between;
  */
}

.my-account-catalog-no-results {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  width: fit-content;
  text-align: center;
}

.my-account-catalog-record img {
  object-fit: contain;
  width: 100px;
}

div.my-account-due-date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;
  color: #484E80;
  border-bottom: 1px solid #474851;
  margin-bottom: 14px;
  gap: 6px;
  font-size: 15px;
  letter-spacing: .2px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.my-account-text-container {
  flex-shrink: 1;
}

.due-date-time {
  margin-left: 2px;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #C6DAF2;
  color: #484E80;
}

.due-date-time.overdue {
  background: #a90a760f;
  border: 2px solid #87085e;
  color: #87085e;
  border-radius: 30px;
}

.due-date-time.overdue svg {
  position: relative;
  top: -1px;
  height: 18px;
  fill: #87085e;
}

.my-account-catalog-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.my-account-catalog-author {
  margin-bottom: 4px;
  color:#6e6f7b;
}

.my-account-catalog-material-type {
  margin-bottom: 14px;
  color: #6e6f7b;
}

.my-account-catalog-material-type svg {
  height: 14px;
  margin-right: 3px;
  position: relative;
  top: 1px;
  fill: #6e6f7b;
}

.my-account-catalog-renewals {
  margin-bottom: 4px;
}

.my-account-catalog-barcode {
  margin-bottom: 14px;
}

.my-account-catalog-actions {
  display: flex;
  align-items: center;
  margin-top: auto;
  flex-direction: column;
}

button.my-account-catalog-main-cta {
  display: flex;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 22px;
  background: #484E80;
  margin-top: 16px;
  font-weight: bold;
  width: 100%;
  color: #fff;
  font-weight: bold;
}

a.my-account-action-link {
  font-weight: normal;
  font-family: proximaNova;
  margin-top: 14px;
  display: inline-block;
  border-bottom: 1px solid;
  width: fit-content;
}

button.history-delete-item-button {
  margin-left: auto;
}

button.history-delete-item-button svg {
  height: 12px;
}

button.history-delete-item-button svg path {
  fill:#A91717;
  stroke: #A91717;
}

.account-top-tiles-container {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: left;
  align-items: stretch;
  margin-bottom: 20px;
}

.account-count-container {
  display: flex;
  gap: 10px;
  padding: 16px 24px;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  background: #FFF;
  width: fit-content;
}

.individual-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.account-count-container hr {
  border: 1px solid #ccc;
  rotate: 90deg;
  width: 30px;
}

.account-sort-fiter-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #FFF;
  width: 340px;
}

.account-filter-button-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.account-sort-fiter-container button {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid  #484E80;
  background: #FFF;
  flex-grow: 1;
  color: #484E80;
}

.account-sort-fiter-container button svg {
  height: 15px;
  fill: #484E80;
  position: relative;
  top: -1px;
}

.account-sort-fiter-container button.active {
  color:#9C2273;
  border: 1px solid #9C2273;
}

.account-sort-fiter-container button.active svg {
  fill: #9C2273;
}

.account-sort-fiter-container select {
  display: flex;
  height: 40px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #484E80;
  background: #FFF;
}

button.button-looks-like-link {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color:#484E80;
  border-radius: 0px;
  font-size: 16px;
}

button.button-looks-like-link span {
  border-bottom: 1px solid #484E80;
  padding-bottom: 2px;
}

button.button-looks-like-link svg {
  width: 10px;
  fill: #484E80;
  margin-left: 2px;
}

button.button-looks-like-link svg path {
  fill: #484E80;
}

.pickup-by-text {
  align-items: center;
  background: #c6daf2;
  background: var(--Secondary,#c6daf2);
  border-radius: 12px;
  color: #484E80;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  margin-left: 0px;
  top: -2px;
  padding: 2px 8px;
}

#edit-sierra-pickup-location-overlay .overlay-body {
  max-width: 600px;
}

#edit-email-notification-overlay .overlay-body {
  max-width: 600px;
}

.num-of-renewals {
  margin-top: 14px;
  color:#6e6f7b;
}

.edit-account-data-container {
  margin-top: 12px;
}

div.edit-container .white-tile-container {
  width: unset;
}

.edit-account-label {
  font-family: proximaNovaBold;
  color:#6e6f7b;
}

.my-account-alerts {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  margin-bottom: 30px;
}

.my-account-alerts:empty {
  display: none;
}


@media only screen and (max-width: 1000px) {
  .my-account-alerts {
    grid-template-columns: auto;
  }
}

.my-account-alerts .white-tile-container{
  margin-bottom: 0px;
  line-height: 1.5em;
}

.my-account-alerts .white-tile-container svg {
  height: 22px;
  position: relative;
  top: 1px;
}

.my-account-alerts .white-tile-container svg path {
  fill:#484E80;
}

.my-account-lists-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

@media only screen and (max-width: 1300px) {
  .my-account-lists-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .my-account-lists-container {
    grid-template-columns: 1fr;
  }
}

.my-account-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  overflow-wrap: anywhere;
}

.my-account-list-title {
  margin-bottom: 6px;
}

a.my-account-list-title-link {
  font-size: 18px;
}

.account-list-img-container img {
  width: 100px;
  height: 140px;
  object-fit: contain;
  object-position: top left;
}

.account-list-actions {
  display: flex;
  gap: 16px;
  margin-top: 10px;
}

div.account-list-actions button {
  margin-top: 0px;
}

.booklist-more-actions-container {
  margin-left: auto;
}

.booklist-share-container {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.my-account-list-count, .my-account-list-description {
  margin-bottom: 8px;
}

.search-list-input-container {
  display: flex;
  border: 1px solid #484E80;
  border-radius: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.search-list-input-container svg {
  position: relative;
  left: 5px;
}

.search-list-input-container input[type="text"] {
  border: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  color:#484E80;
  flex-grow: 1;
  min-width: unset;
}

.search-list-input-container input[type="text"]::placeholder {
  color:#484E80;
  opacity: 1;
}

button#create-new-list-my-account {
  font-family: proximaNovaBold;
  color:#484E80;
  background: transparent;
  padding: 0px;
  margin-bottom: 30px;
  font-size: 16px;
}

button#create-new-list-my-account svg { 
  height: 20px;
  position: relative;
  top: 5px;
  left: 1px;
  margin-right: 10px;
}

button#create-new-list-my-account svg path { 
  color:#484E80;
  fill:#484E80;
}

.my-account-edit-header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start ;
}

.my-account-edit-section-btn {
  color:#484E80;
  font-family: proximaNovaBold;
  font-weight: 400;
  border-bottom: 1px solid;
  border-radius: 0px;
  padding:0px;
  font-size: 16px;
  position: relative;
  top: 4px;
}

#search-history-input-container {
  display: flex;
  border: 1px solid #484E80;
  align-items: center;
  padding: 4px 12px 4px 20px;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  justify-content: space-between;
}

#search-history-input-container input[type="text"] {
  padding: 0px;
  margin: 0px;
  border: none;
  border-radius: 0px;
  flex-grow: 1;
  max-width: unset;
  min-width: unset;
}

#search-history-input-container svg {
  height: 18px;
  width: 18px;
  object-fit: contain;
  flex-shrink: 0;
}

#search-history-input-container svg path {
  stroke: #484E80;
}

.account-sort-and-more-flex {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 12px;
  align-items: flex-end;
}

.more-actions-btn {
  border-radius: 10px;
  display: flex;
}

.more-actions-btn svg {
  height: 30px;
}

.hold-note-container {
  background-color: #c1472e20;
  display: flex;
  gap: 10px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 6px;
  border: 2px solid #c1472e;
}

.hold-note-container svg {
  fill: #c1472e;
  height: 20px;
}


/*

OVERLAY SPECIFIC CSS

*/


body.overlay-mode {
  overflow: hidden;
}

.overlay-container {
  display: none;
  overflow-x: hidden;
  left: 0;
}

.overlay-container.overlay-active {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  
  visibility: visible;
  transition: background-color .2s ease;
}

.overlay-container.overlay-active.transition-state {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease;
}

.overlay-body {
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 15px;
  width: 80%;
  max-width: 1300px;
  min-height: 300px;
  height: min-content;
  margin-top: calc(100vh);
  transition: margin-top .2s ease;
}

.overlay-container.overlay-active .overlay-body.active {
  margin-top: calc(5vh);
  transition: margin-top .2s ease;
}


.overlay-container.side-slide {
  display: none;
  overflow-x: hidden;
  left: 0px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .2s ease;
}

.overlay-container.side-slide.overlay-active {
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  position: fixed;
  z-index: 9;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  visibility: visible;
  transition: background-color .2s ease;
}

.overlay-body.side-slide-right {
  border-radius: 0px;
  padding:20px 30px 25px 20px;
  width: 290px;
  height: min-content;
  min-height: 100px;
  margin-top: 0px;
  position: relative;
  left: calc(100vw);
  transition: left .2s ease;
}

.overlay-container .overlay-body.active.side-slide-right {
  border-radius: 0px;
  padding:20px 30px 25px 20px;
  width: 290px;
  height: min-content;
  min-height: 100px;
  position: relative;
  margin-top: 0px;
  left: calc(100vw - 290px);
  transition: left .2s ease;
  box-shadow: 2px 2px 2px 2px gray;
}

.close-overlay {
  float: right;
  background: transparent;
  margin: 0px;
  padding: 0px;
}

body div.overlay-body h3 {
  margin-top: 0px;
}

#get-this-item-overlay .formats-container .status {
  font-weight: normal;
}

#get-this-item-overlay .formats-container {
  margin-bottom: 30px;
  margin-top: 20px;
}

.overlay-body button[type="submit"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 50px;
  background: #484E80;
  padding: 12px 20px;
  color: #fff;
  margin-top: 30px;
}

.overlay-body label {
  font-family: proximaNovaBold;
  display: block;
  margin-bottom: 7px;
}

.get-this-item-title {
  font-family: proximaNovaBold;
  font-size: 22px;
  margin-bottom: 6px;
}

.get-this-item-author {
  color:#6E6F7B;
  font-size: 18px;
  font-family: proximaNovaBold;
}

img.get-this-item-overlay-image {
  object-fit: contain;
  object-position: top left;
  width: 150px;
}

#sierra-hold-pickup-location {
  min-width: 250px;
}

#login-overlay-invalid-credential-msg {
  margin-bottom: 20px;
}

#loading-overlay {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

#loading-overlay .loading-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@keyframes loading {
  from {left: -10%; width: 35px;}
  50% {left: 90%;}
  to {left: -10%;  width: 35px;}
}

.loader {
  height: 5px;
  width: 200px;
  position: relative;
  overflow: hidden;
  background-color: #6b6b6b;
  margin-top: 12px;
  border-radius: 4px;
}

.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -30px;
  width: 30px;
  height: 5px;
  background-color: #c2c2c2;
  animation: loading 1s linear infinite;
  border-radius: 4px;
}

#small-loading-overlay {
  left: 0;
  right: 0;
  top: 0;
  z-index: 12;
  position: fixed;
  width: 100%;
  background-color: transparent;
}

#small-loading-overlay:before {
  display: block;
  position: absolute;
  content: "";
  left: -10px;
  width: 100%;
  height: 4px;
  background-color: #9C2273;
  animation: loadingTop 2s ease;
}

@keyframes loadingTop {
  from {left: -95%; width: 100%}
  to {left: -5%;  width: 110%}
}

#success-overlay, #error-overlay {
  position:fixed;
  top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
}

#success-overlay .success-overlay-container {
  padding: 20px;
  border-radius: 4px;
  background: #4A7C30;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  color: #fff;
}

#error-overlay .error-overlay-container {
  padding: 20px;
  border-radius: 4px;
  background: #A91717;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  color: #fff;
}

#error-overlay div a, #success-overlay div a {
  color: #fff;
  font-family: proximaNova;
  font-weight: normal;
  border-bottom: 1px solid;
}

.success-overlay-link, .error-overlay-link {
  flex-shrink: 0;
}

#error-overlay .flex-small-gap,  #success-overlay .flex-small-gap {
  margin-top: 4px;
}

.success-overlay-text, .error-overlay-text {
  flex-shrink: 1;
}

#self-checkout-overlay .overlay-body {
  max-width: 700px;
}

#self-checkout-overlay #interactive {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

#self-checkout-overlay #interactive:empty {
  display: none;
}

#scanner select {
  display: none!important;
}

#camera-scan-label {
  margin-bottom: 10px;
}

#result-strip {
  margin-top: 10px;
}

#result-strip ul {
  padding-left: 10px;
}

#self-checkout-overlay #interactive video {
  overflow: hidden;
  box-sizing: border-box;
  object-fit: cover;
  overflow-clip-margin: content-box;
  width: 500px;
  height: 130px;
}

#manual-checkout {
  display: flex;
}

#checkoutImage {
  width: 80px;
  height: 100px;
  object-fit: contain;
  object-position: top left;
}

.checkoutUl {
  list-style-type: none;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
}

.wrapper {
  max-width: 90%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0px;
  grid-auto-rows: minmax(50px, auto);
  align-content: center;
}

.self-checked-out-item {
  display: flex;
  gap: 20px;
}

#item-barcode {
  min-width: 200px;
  width: 200px;
}

#manual-checkout button[type="submit"] {
  position: relative;
  top: -2px;
}

canvas.drawingBuffer {
  position: absolute;
  left: 86px;
  display: none;
}

iframe#access-overdrive-container {
  object-fit: contain;
  width: 100%;
  height: 550px;
  border: none;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  position: relative;
  top: -25px;
}

iframe#access-overdrive-container::-webkit-scrollbar {
  display: none;
}

.overdrive-access-loading-spinner {
  display: flex;
  justify-content: center;
  position: relative;
  top: 80px;
}

@keyframes spinno {
  from {rotate: 0deg}
  to {rotate: 360deg}
}

.overdrive-access-loading-spinner svg {
  width: 50px;
  height: 50px;
  color:#484E80;
  animation: spinno 1.4s linear infinite;
}

.sierra-hold-location-message {
  margin-top: 20px;
  margin-bottom: 20px;
}

.overdrive-hold-apology-message {
  margin-bottom: 20px;
}

.overdrive-hold-apology-message svg {
  height: 24px;
  fill:#A90A76;
  position: relative;
  top: -2px;
}

.volume-option-container {
  display: flex;
  gap: 15px;
  margin-bottom: 14px;
}

.volume-option-container label {
  margin-bottom: 0px;
  position: relative;
  top: 2px;
}

.multiple-sierra-item-copy-holds-container {
  display: flex;
  margin-bottom: 10px;
  gap: 15px;
  align-items: center;
}

div.multiple-sierra-item-copy-holds-container label {
  margin-bottom: 0px;
  position: relative;
  top: 1px;
}

#digital-card-overlay .overlay-body {
  max-width: 800px;
}

.digital-card-container {
  display: flex;
  justify-content: center;
  font-size: 18px;
  flex-direction: column;
  align-items: center;
}

.digital-card-container div {
  font-size: 18px;
  margin-bottom: 3px;
  font-family: proximaNovaBold;
}

img.digital-card-img {
  width: 130px;
  margin-bottom: 10px;
  margin-top: 10px;
}

img.barcode {
  margin-top: 12px;
}


#add-to-list-overlay div.overlay-body {
  max-width: 800px;
}

#create-new-list-overlay div.overlay-body {
  max-width: 800px;
}

#add-to-list-overlay button.default-blue-button {
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
}

#create-new-list-btn-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color:#484E80;
  font-size: 17px;
  padding: 0px;
  font-family: proximaNovaBold;
}

#create-new-list-btn-overlay svg {
  position: relative;
  top: -1px;
  fill:#484E80;
}

#overlay-lists-container {
  min-height: 100px;
  overflow-y: scroll;
  max-height: 260px;
}

.select-format-for-list-label {
  font-size: 17px;
  margin-bottom: 5px;
  font-family: proximaNovaBold;
}

#add-to-list-overlay hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

div#add-to-list-overlay h3 {
  margin-bottom: 12px;
}

.list-overlay-formats-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}


@media only screen and (max-width: 900px) {
  .list-overlay-formats-container {
    grid-template-columns: 1fr 1fr;
  }
}

button.format-overlay-select-button {
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #6e6f7b;
  padding: 8px;
  display: flex;
  justify-content: center;
  gap: 10px
}

button.format-overlay-select-button svg {
  height: 15px;
  fill:#ccc;
  position: relative;
  top: -1px;
}

button.format-overlay-select-button.selected {
  border: 1px solid #484E80;
  color: #474851;
}

button.format-overlay-select-button.selected svg {
  fill:#484E80;
}

.overlay-user-list {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  gap: 15px;
}

.overlay-list-img img {
  object-fit: contain;
  object-position: top left;
  width: 70px;
  height: 70px;
}

.overlay-list-title {
  flex-grow: 1;
}

.overlay-list-title div:first-of-type {
  margin-bottom: 2px;
}

.overlay-list-checkbox {
  margin-right: 20px;
}

.overlay-list-checkbox input {
  height: 18px;
  width: 18px;
}

input#new-list-overlay-name {
  margin-bottom: 20px;
  width: 100%;
}

#create-new-list-overlay div.overlay-body {
  max-width: 1000px;
  height: auto;
}

textarea#new-list-overlay-description {
  width: 100%;
  min-height: 120px;
  margin-bottom: 20px;
}


#edit-list-overlay label {
  margin-top: 20px;
}

input#edit-list-overlay-name {
  width: 100%;
}

#edit-list-overlay textarea {
  width: 100%;
  height: 100px;
}

.edit-list-public-label {
  margin-bottom: 5px;
}

.edit-list-privacy-container {
  margin-top: 20px;
}

input#make-list-public {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

label#make-list-public-label {
  display: inline-block;
  box-sizing: border-box;
  background-color: #FCFCFD;
  border-radius: 100px;
  border: 2px solid #7F808C;
  width: 58px;
  height: 36px;
}

label#make-list-public-label.checked {
  background-color: #9C2273;
  border: 2px solid #9C2273;
}

label#make-list-public-label svg {
  fill: #7F808C;
  position: relative;
  top: 2px;
  left: 2px;
}

label#make-list-public-label.checked svg {
  fill: #FCFCFD;
  position: relative;
  top: 2px;
  left: 24px;
}

#edit-list-custom-url {
  width: 100%;
}

#list-url-taken-msg {
  margin-bottom: 4px;
}

.form-error-message {
  color: #A91717;
  margin-top: 10px;
}

.list-items-container {
  min-height: 200px;
  margin-bottom: 20px;
  overflow-y: scroll;
  max-height: 400px;
}

.edit-list-items-item-container {
  padding: 15px 0px;
  border-bottom: 1px solid #ccc;
  display: flex;
  gap: 30px;
}

.edit-list-items-item-container:last-of-type {
  border-bottom: none;
}

.edit-list-items-item-container.dragging {
  background-color: #dcdcdc;
}

.edit-list-items-item-container.dragging button.edit-list-items-sort-btn {
  background-color: #dcdcdc;
}

.edit-list-items-item-container.dragging button.edit-list-items-sort-btn svg {
  background-color: #dcdcdc;
}

img.edit-list-items-item-img {
  object-fit: contain;
  width: 80px;
  height: 80px;
  object-position: top center;
}

.edit-list-items-item-title {
  margin-bottom: 4px;
}

.edit-list-items-item-author {
  margin-bottom: 4px;
}

.edit-list-items-item-annotation {
  margin-bottom: 10px;
}

button.edit-list-items-annotation-btn {
  padding: 6px 10px;
  color:#484E80;
  border: 1px solid #484E80;
  border-radius: 30px;
}

button.edit-list-items-remove-from-list-btn {
  padding: 6px 10px;
  color:#A91717;
  border: 1px solid #A91717;
  border-radius: 30px;
}

label.list-item-annotation-label {
  font-size: 15px;
  margin-bottom: 4px;
}

textarea.list-item-annotation-textarea {
  margin-bottom: 20px;
  width: calc(50vw);
}

.list-sort-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.edit-list-items-sort-btn {
  margin: 0px;
  padding: 0px;
  position: relative;
  left: 6px;
  cursor: move;
}

button.edit-list-items-sort-btn svg {
  height: 28px;
  fill:#474851;
}

.pin-value-no-match-msg, .username-taken-msg, .username-invalid-format {
  color: #A91717;
  margin-bottom: 10px;
}

.margin-bottom-ten {
  margin-bottom: 10px;
}

.margin-bottom-twenty {
  margin-bottom: 20px;
}

.phone-incorrect-format {
  color: #A91717;
}

#edit-contact-info-overlay select {
  width: 250px;
}

input#address-street {
  min-width: unset;
}

input#address-city {
  min-width: unset;
}

input#address-state {
  width: 60px;
  min-width: unset;
}

input#address-zip {
  width: 90px;
  min-width: unset;
}

.rebalancing-items-container {
  min-height: 50px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.rebalancing-item-container {
  align-items: flex-start;
  margin-bottom: 4px;
}

#rebalacing-claim-overlay .overlay-body {
  max-width: 600px;
}

select#rebalancing-pickup-location {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 5px;
}

#rebalacing-claim-overlay button.default-blue-button.full-width {
  width: 100%;
}

#my-account-side-drawer .name-container, button.name-container {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  align-items: center;
}


#my-account-side-drawer .initials-circle-profile, button .initials-circle-profile {
  border-radius: 40px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  color: #484E80;
  flex-shrink: 0;
}

#my-account-side-drawer hr {
  margin: 20px 1px;
  border: none;
  border-bottom: 1px solid #ccc;
}

#my-account-side-drawer a {
  border-bottom: none;
}

#my-account-side-drawer .logout-link-container a {
  color:#A91717;
}

#my-account-side-drawer a svg {
  margin-right: 10px;
  position: relative;
  top: 1px;
  fill:#484E80;
}

#my-account-side-drawer .switch-add-account-link-container svg {
  top: 2px;
}

#my-account-side-drawer .logout-link-container svg {
  fill:#A91717;
  top: 2px;
}

.overlay-top-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  margin-bottom: 10px;
}

.overlay-top-button-container button svg {
  fill:#484E80;
  height: 26px;
  position: relative;
  left: -3px;
}

.switch-add-account-link-container {
  margin-top: 20px;
}


#add-account-form  label {
  margin-top: 12px;
  margin-bottom: 5px;
}

#add-account-form  input[type="submit"] {
  padding: 10px 20px;
  background: #484E80;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 0px;
  border:none;
  border-radius: 30px;
}

.switch-accounts-names-container {
  margin-top: 15px;
}

.switch-accounts-names-container button.name-container.switch-accounts {
  text-align: left;
  padding: 0px;
  margin-top: 20px;
}

.sierra-board-game-message {
  font-family: proximaNovaBold;
  margin-top: 10px;
  margin-bottom: 20px;
}



/*

PIN RESET FORM PAGE

*/

.pin-page .main-app-container {
  margin-left: 13px;
}

form#request-pin-reset-email label {
  display: block;
  font-family: proximaNovaBold;
  margin-top: 20px;
  margin-bottom: 4px;
}

form#request-pin-reset-email input {
  margin-bottom: 20px;
}

form#request-pin-reset-email button[type="submit"] {
  color:#484E80;
  padding: 8px 16px;
  border: 1px solid;
  border-radius: 30px;
  font-size: 16px;
}

#installApp {
  position: fixed;
  bottom: 0px;
  z-index: -1;
  width: 0px;
  height: 0px;
}

#installApp.show {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:5px 20px;
  top: 2px;
  background-color:#484E80;
  color: #fff;
  width: 100px;
  height: 50px;
  z-index: 1;
  left: calc(50vw - 50px);
}

#history-opt-out-overlay .overlay-body {
  max-width: 800px;
}

/*

RESPONSIVE

*/


@media only screen and (max-width: 1100px) {

  .main-app-container, .item-page .main-app-container {
    padding: 15px;
  }

  .nav-bar {
    padding: 6px 18px 4px;
  }

  .footer {
    padding: 50px 20px 100px;
  }

  .navbar-buttons-container {
    gap: 15px
  }

  .alert-message {
    padding: 15px;
  }

  .nav-bar .logo-container img {
    width: 170px;
  }

  a.get-a-card-nav-link {
    display: none;
  }

  .filter-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 0px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    height: 0px;
    overflow: hidden;
    transition: height .1s linear;
  }

  .filter-container.overlay-mode {
    height: 700px;
    overflow-y: scroll;
    transition: height .1s linear;
  }

  .filters {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  button.close-modal-overlay {
    display: block;
    position: relative;
    left: 95px;
    top: 2px;
    background: transparent;
  }

  .fixed-filters-header {
    /*
    position: fixed;
    */
    background-color: #fff;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 9;
  }

  /*
  .filters-main-content hr:last-of-type {
    visibility: hidden;
  }
  */

  .fixed-filters-header hr {
    padding: 0px;
    margin: 0px 100px;
    display: block;
  }

  .fixed-filters-footer hr  {
    padding: 0px;
    margin: 0px;
    display: block;
  }

  .filter-header-flex-container {
    display: flex;
    gap: calc(50vw - 110px);
  }

  .filter-footer-flex-container {
    display: flex;
    gap: 20px;
    padding: 20px 0px 20px;

  }
  
  .fixed-filters-footer {
    display: block;
    /*
    position: fixed;
    */
    background-color: #fff;
    width: 100%;
    bottom: 0px;
    left: 0px;
    padding: 0px 100px;
    z-index: 9;
  }

  .filters-main-content {
    padding: 80px 100px;
  }

  h3.filter-and-sort-header {
    text-align: center;
    margin-top: 20px;
  }  

  .launch-filters-btn {
    display: flex;
    height: 44px;
    width: 100%;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 22px;
    background:#9C2273;
    color: #fff;
    margin-bottom: 25px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
  }

  .launch-filters-btn svg {
    margin-right: 2px;
    position: relative;
    left: -5px;
    top: -1px;
  }

  section {
    display: block;
    margin-top: 10px;
  }

  .item-page-image-container img {
    object-position: top center;
    object-fit: contain;
    width: 200px;
    height: 245px;
  }

  .item-page-header-flex-container {
    gap: 30px;
  }

  div.sample-link-container {
    left: 1px;
  }

  .homepage-discovery-container {
    max-width: unset;
  }

  div.my-account-section-container {
    gap: 20px;
  }

  .item-location-table-call-num {
    display: none;
  }

  #get-this-item-overlay .flex-medium-gap img {
    display: none;
  }

  .spellcheck-container {
    margin-left: 4px;
  }

  .discover-carousel-card {
    max-width: unset;
  }

}
@media only screen and (max-width: 1100px) {
  .footer .horizontal-links-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .footer .horizontal-links-container a {
    font-family: proximaNovaBold;
    font-size: 16px;
    color: #fff;
    border-bottom: none;
    display: block;
    border-bottom: 1px solid transparent;
    width: fit-content;
  }
  
  .footer .horizontal-links-container a:hover {
    text-decoration: underline;
  }
  
  .footer .horizontal-links-container .footer-logo {
    margin-right: auto;
    width:150px;
  }
  .footer .horizontal-links-container .footer-logo-link{
    margin-right:auto !important;
  }
}

@media only screen and (max-width: 970px) {

  a.get-a-card-nav-link {
    display: none;
  }

  #header-homepage-link-container {
    display: none;
  }

}


@media only screen and (max-width: 800px) {

  .main-app-container, .item-page .main-app-container {
    padding: 12px;
  }

  span.navbar-locations-text {
    display: none;
  }

  span.navbar-translate-text {
    display: none;
  }

  button.navbar-translate-button svg{
    height: 18px;
    margin-right: 3px;
  }

  a.navbar-locations-link svg {
    height: 19px;
    margin-right: 8px;
    top: 2px;
  }

  .nav-bar .logo-container img {
    width: 150px;
  }

  .navbar-buttons-container {
    gap: 10px
  }

  .result-container, .no-result-container {
    padding: 24px;
    margin: 0px 0px 32px 0px
  }
  
  .result-container .image-container img {
    height: 150px;
    width: 150px;
  }

  .overlay-body {
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    padding: 20px;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    min-height: 100%;
  }

  .searchbox {
    width: 100%;
  }

  .launch-filters-btn {
    max-width: unset;
  }

  .filters-main-content {
    padding: 80px 50px;
  }

  .fixed-filters-header hr  {
    margin: 0px 50px;
  }

  .fixed-filters-footer {
    padding: 0px 50px ;
  }

  button.close-modal-overlay {
    left: 45px;
  }

  .item-page-header-flex-container {
    flex-flow: row wrap;
    gap: 20px;
  }

  .item-page-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .account-count-container, .account-sort-fiter-container {
    padding: 16px;
    width: 100%;
  }

  .search-carousel-tip-top-container {
    padding: 0px;
  }

  a.cam-carousel-item img {
      height: 160px;
  }

  .cam-carousel button {
    display: none;
  }

  .primary-item-details-container {
    flex-direction: column;
    gap: 8px;
  }

  .list-sort-button-container {
    display: none;
  }

} 

@media only screen and (max-width: 768px /* originally 700 */) {
  .footer .horizontal-links-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .footer .horizontal-links-container a {
    font-family: proximaNovaBold;
    font-size: 16px;
    color: #fff;
    border-bottom: none;
    display: block;
    border-bottom: 1px solid transparent;
    width: fit-content;
    padding: .5rem;
  }
  
  .footer .horizontal-links-container a:hover {
    text-decoration: underline;
  }
  
  .footer .horizontal-links-container .footer-logo {
    width:150px;
    margin-top: 0px;
  }
  .footer .horizontal-links-container .footer-logo-link{
    margin-right:auto !important;
    margin-left: auto !important
  }
}
@media only screen and (max-width: 768px){
  .footer .footer-columns-container {
    display: none;
  }
  .footer #block-bklyn-megafooter-2 ul {
    display: block !important;
    list-style: none;
    margin: 0;
    padding: 0 10px 0 0;
  } 
  .footer .accordions-container {
    display:block;
    max-width: 100%;
    border-radius: 15px;
  }
  .footer .accordions-container {
    display:block;
    max-width: 100%;
    border-radius: 15px;
  }
  .footer-extra-links {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {

  .main-app-container, .item-page .main-app-container {
    padding: 10px 8px;
  }

  .navbar-buttons-container {
    gap: 8px
  }

  .footer {
    padding: 50px 10px 100px;
  }

  .app-header {
    padding: 25px 0px 0px;
  }

  .nav-bar .logo-container img {
    width: 120px;
    top: 7px;
  }

  .item-page .app-header, .item-page section, .account-page section {
    padding: 10px 8px;
    margin: 0px;
  }

  .account-page .app-header {
    padding: 8px;
    margin: 0px;
  }

  .account-page .app-header h1 {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .search-page .app-header {
    padding-top: 10px;
  }

  .my-account-content-container {
    margin-top: 20px;
  }

  .result-container {
    flex-direction: column;
    margin: 0px 0px 32px 0px
  }

  .filters-main-content {
    padding: 80px 25px;
  }

  .fixed-filters-header hr {
    margin: 0px 25px;
  }

  .fixed-filters-footer {
    padding: 0px 25px;
  }

  button.close-modal-overlay {
    left: 20px;
  }

  .result-container .image-container {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 24px;
    left: 0px;
  }

  .result-container .image-container img {
    height: 180px;
    width: 180px;
    object-position: center;
  }

  .result-summary {
    display: none;
  }

  .result-container .subject-pill-btn-container {
    display: none;
  }
  
  button.clear-all-filters-footer, button.view-results-filters-footer  {
    width: calc(50vw - 45px);
  }

  .booklist-header {
    padding: 0px 10px;
    margin-top: 10px;
  }

  h1.booklist-title {
    font-size: 28px;
  }

  #fixed-bottom-nav {
    justify-content: space-between;
  }

  .format-selection-container {
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
  }

  a.individual-format {
    padding: 5px 10px;
    font-size: 15px;
  }

  .overlay-container.overlay-active .overlay-body.active {
    margin-top: 10vh;
    transition: margin-top .2s ease;
  }

  .overlay-body {
    padding: 20px 18px;
  }

  .cam-carousel-content-container {
    display: flex;
    gap: 30px;
  }

  .cam-carousel-content-container .carousel-item-author {
    display: none;
  }

  a.cam-carousel-item img {
      height: 150px;
  }

  .cam-carousel button {
    display: none;
  }

  .item-page-header-flex-container {
    margin-top: 20px;
  }
  
  .my-account-login-container {
    margin-left: 20px;
  }

  #self-checkout-overlay #interactive video {
    width: calc(90vw);
    height: 130px;
  }

  .account-count-text {
    font-size: 15px;
    text-align: center;
  }

  .account-top-tiles-container {
    justify-content: center;
  }

  iframe#access-overdrive-container {
    height: 700px;
  }

  .my-account-alerts .white-tile-container{
    padding: 16px;
  }

  .overlay-list-img img {
    width: 50px;
    height: 60px;
  }

  #add-to-list-overlay button.default-blue-button {
    width: 100%;
  }

  .gtranslate_wrapper{
    left: 10px;
  }

  .gtranslate_wrapper select {
    width: 30px;
    text-overflow: unset;
  }

  .result-container.website {
    gap: 0px;
  }

  button.bottom-nav-btn {
    font-size: 15px;
  }

  .footer button.scrolltotop { 
    display: none!important;
  }

  
  .overlay-body.side-slide-right {
    border-radius: 0px;
    padding:20px 30px 25px 20px;
    width: 280px;
    height: min-content;
    min-height: 100px;
    margin-top: 0px;
    position: relative;
    left: calc(100vw);
    transition: left .2s ease;
  }

  .overlay-container .overlay-body.active.side-slide-right {
    border-radius: 0px;
    padding:20px 30px 25px 20px;
    width: 280px;
    height: min-content;
    min-height: 100px;
    position: relative;
    margin-top: 0px;
    left: calc(100vw - 280px);
    transition: left .2s ease;
  }

  #self-checkout-overlay div.overlay-body.active {
    margin-top: 10px!important;
  }

  #success-overlay, #error-overlay {
    top: 0px;
  }

  a.related-booklist:nth-of-type(3), a.related-booklist:nth-of-type(4) {
    display: none;
  }

} 

@media only screen and (max-width: 500px) { 
  .related-booklist-image-container {
    flex-basis: 120px;
  }

  a.related-booklist img {
    width: 120px;
    height: 120px;
  }

  a.related-booklist img.booklist-first-image {
    top: -80px;
  }
}

@media only screen and (max-width: 450px) { 

  
  .nav-bar {
      padding: 6px 12px 4px 8px;
  }

  .nav-bar .logo-container img {
    width: 115px;
  }

  a.navbar-donate-button svg {
    display: none;
  }

  .gtranslate_wrapper {
    left: -10px;
  }

  a.navbar-locations-link {
    position: relative;
    left: -8px;
  }

  a.navbar-locations-link svg {
    height: 19px;
    margin-right: 0px;
    top: 2px;
  }

  .gtranslate_wrapper select {
    padding: 6px;
    color: transparent;
  }

  .related-booklist-summary {
    display: none;
  }

}

@media only screen and (max-width: 400px) { 
  .nav-bar .logo-container img {
    width: 105px;
    top: 8px;
  }

  .navbar-buttons-container {
    gap: 5px;
  }

  a.login-logout-button, a.navbar-donate-button {
    padding: 4px 10px;
  }

  input[type="text"].filter-search-input {
    border: none;
    padding: 2px;
    margin: 0px;
    color:#484E80;
    min-width: unset;
    max-width: unset;
    width: calc(55vw);
  }

  .filterset-autocomplete-search {
    max-width: calc(75vw);
  }

  #digital-card-text-to-hide {
    display: none;
  }

  #marc-record-section-link {
    display: none;
  }
  
}

@media only screen and (max-width: 360px) {
  button.clear-all-filters-footer, button.view-results-filters-footer  {
    width: unset;
  }
}

@media only screen and (max-width: 356px) {
  a.navbar-donate-button {
    padding: 3px 8px;
    font-size: 13px;
  }

  a.login-logout-button {
    padding: 3px 8px;
    font-size: 13px;
  }
}


@media print {
  .App.search-page .filter-container, .navbar-buttons-container, .get-this-item, .add-to-list, .subject-pill-btn-container, .rebalancing-claim-button {
    display: none !important;
  } 
  .App.account-page .navbar-buttons-container{
    display: none !important;
  }
  .App.item-page .navbar-buttons-container, .get-this-item, .add-to-list, .share{
    display: none !important;
  }
  .App.item-page .formats-container {
    margin-top: 10px !important;
  }
  .App.item-page h2.item-page-author {
    margin-bottom: 0px !important;
  }
  .App.item-page h3{
    margin: 8px 0px 10px 0px !important;
  }
  .App.item-page .result-detail-actions.wrap {
    display: none !important;
  }
  #fixed-bottom-nav{
    display:none !important;
  }
  .footer .scrolltotop {
    display: none !important;
  }
  .result-container{
    padding: 0px !important;
    margin-bottom: 25px !important;
  }
  .main-app-container {
    padding: 10px 0px !important;
  }
  .footer {
    display: none !important;
  }
  .result-title{
    
     font-size: 16px !important ;
  }
  .image-container img {
    object-fit: contain;
    width: 200px;
    height: 200px;
    object-position: top;
  }
  .my-account-section {
    display: none !important;
  } 
  .my-account-section.active{
    display: block !important;
  }
}